import Vue from 'vue'
import i18n from '@/i18n'
export const runOnNextTick = fn =>
  function handler(value, oldValue) {
    this.$nextTick(() => {
      fn.call(this, value, oldValue)
    })
  }

export const debounce = (fn, wait = 1000) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn.apply(this, args), wait)
  }
}

export const sendSuccessToast = message => {
  Vue.toasted.clear()
  Vue.toasted.success(message, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 7000,
    fullWidth: true,
    singleton: true,
    keepOnHover: true,
    fitToScreen: true
  })
}

export const sendErrorToast = err => {
  Vue.toasted.clear()
  Vue.toasted.error(err, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 7000,
    fullWidth: true,
    singleton: true,
    keepOnHover: true,
    fitToScreen: true
  })
}

export const sendInfoToast = err => {
  Vue.toasted.clear()
  Vue.toasted.info(err, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    duration: 7000,
    fullWidth: true,
    singleton: true,
    keepOnHover: true,
    fitToScreen: true
  })
}

export const getRequestMessage = (type, model, code = 'default') => {
  return i18n.t(`request.${type}.${model}.${code}`)
}
