<template>
  <div id="app">
    <Navbar />
    <div>
      <loading :is-loading="isLoading" />
      <router-view />
    </div>
  </div>
</template>

<script>
  import Navbar from '@/components/layout/NavBar.vue'
  import Loading from '@/components/Loading.vue'
  import { mapState, mapActions } from 'vuex'
  import { globalProperties } from '@/main'

  export default {
    name: 'App',
    components: {
      Navbar,
      Loading
    },
    computed: {
      ...mapState('common', ['isLoading'])
    },
    methods: {
      ...mapActions('user', ['fetchCampaignYear'])
    },
    async created() {
      this.$store.commit('user/setUserMatriculeConnexion', this.$userInfos.uid)
      this.$store.commit('user/setUserDispalyName', this.$userInfos.displayName)
      this.$store.commit('user/setUserMail', this.$userInfos.mail)
      this.$store.commit('user/setUserFirebaseRoles', globalProperties?.roles)

      await this.fetchCampaignYear()
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Rubik');

  #app {
    font-family: Rubik, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $darkblue;
    height: 100%;
    margin-bottom: 1em;
    overflow-y: auto;
  }
</style>
