<template>
  <div class="navitem">
    <v-svg v-if="iconName" :name="iconName" :size="'2rem'" class="logo-svg" />
    <span>{{ libelle }}</span>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg.vue'
export default {
  name: 'NavItem',
  components: {
    VSvg
  },
  props: {
    /**
     * item label
     */
    libelle: {
      type: String,
      required: false
    },
    /**
     * icon name
     */
    iconName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.navitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
  }
}
</style>
