import Vue from 'vue'
import { extendWithAuthorization, http } from '@/http'
import { sendErrorToast } from '@/utils/utils'

const state = {
  supplierPurchase: [],
  supplierPurchaseExport: [],
  total: 0,
  rowCount: 10,
  currentPage: 1,
  offset: 1
}

const mutations = {
  setSupplierPurchase(state, datas) {
    state.supplierPurchase = datas.result
    state.total = datas.total
  },

  setSupplierPurchaseExport(state, supplierPurchaseExport) {
    state.supplierPurchaseExport = supplierPurchaseExport
  },

  setRowCount(state, rowCount) {
    state.rowCount = rowCount
  },

  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage
  }
}

const actions = {
  async fetchSupplierPurchase({ state, commit }, params) {
    let datas
    const extendedHttp = await extendWithAuthorization(http)
    const offset = state.rowCount * state.currentPage - state.rowCount

    const searchParams = {
      search: params.search,
      campaignYear: parseInt(params.campaignYear),
      userMatriculeConnexion: params.userMatriculeConnexion,
      isCdg: params.isCdg,
      ...(params.isActivated && { isActivated: 1 }),
      row_count: state.rowCount,
      offset
    }

    return new Promise(async (resolve, reject) => {
      try {
        datas = await extendedHttp.get(`directory/supplierPurchase`, { searchParams }).json()

        commit('setSupplierPurchase', datas)
        resolve()
      } catch (error) {
        sendErrorToast('Erreur lors de la récupération de la liste des fournisseurs')
        reject(error)
      }
    })
  },

  async fetchSupplierPurchaseExport({ state, commit }, params) {
    let datas
    const extendedHttp = await extendWithAuthorization(http)

    const searchParams = {
      campaignYear: parseInt(params.campaignYear),
      ...(params.isActivated && { isActivated: 1 })
    }

    return new Promise(async (resolve, reject) => {
      try {
        datas = await extendedHttp.get(`directory/supplierPurchase/export`, { searchParams }).json()

        commit('setSupplierPurchaseExport', datas)
        resolve()
      } catch (error) {
        sendErrorToast('Erreur lors de l‘exportation des fournisseurs')
        reject()
      }
    })
  },

  async fetchSupplierPurchaseExportYear({ state, commit, rootState }, params) {
    let data
    const extendedHttp = await extendWithAuthorization(http)
    const searchParams = {
      nameFile: params.nameFile,
      nameBucket: params.nameBucket
    }
    try {
      data = await extendedHttp.get(`directory/domnloadFileExport`, { searchParams }).json()
      return data
    } catch (error) {
      /* sendErrorToast('Erreur lors de la récupération des paramètres')*/
    }
  }
}

const getters = {
  supplierPurchase(state) {
    return state.supplierPurchase
  },
  supplierPurchaseExport(state) {
    return state.supplierPurchaseExport
  },
  total(state) {
    return state.total
  },
  rowCount(state) {
    return state.rowCount
  },
  currentPage(state) {
    return state.currentPage
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
