<template>
  <div>
    <div class="parent">
      <v-svg class="icon" :name="params.value" :size="'0.9em'" />
      <span>{{ $t('rules.choice.'+params.value) }}</span>
    </div>
  </div>
</template>

<script>
  import VSvg from '@/components/base/Svg'
  export default {
    name: "cellRenderer",
    components: {
      VSvg
    }
  }
</script>

<style scoped>
  span{
    padding: 5px;
  }
</style>


