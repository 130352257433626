import Vue from 'vue'

const state = {
    isLoading: false
}

const mutations = {
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
}

const actions = {}

const getters = {}

export default {
    state,
    getters,
    mutations,
    actions
}
