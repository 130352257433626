<template>
  <div class="navbar">
    <router-link id="logo" class="logo" tag="div" to="/">
      <v-svg name="orgasign" class="logo-svg" />
    </router-link>
    <nav>
      <router-link :to="{ name: 'Home' }" :class="[isDataActive && 'router-link-exact-active']">
        <NavItem :libelle="$t('navbar.data')" icon-name="data" />
      </router-link>
      <router-link v-if="isSuperAdmin" :to="{ name: 'SettingBuAssistant' }" :class="[currentRoute === 'userManage' && 'router-link-exact-active']">
        <NavItem :libelle="$t('navbar.setting')" icon-name="setting" />
      </router-link>
    </nav>
    <div class="navRight">
      <select v-model="selectedYear" class="campaignYear mr-3" :disabled="$route.name !== 'Home'" @change="onChange($event)">
        <option v-for="(year, index) in years" :key="index">
          {{ year.campaignYear }}
        </option>
      </select>
      <NavAccount />
      <div class="logout" @click="logOut">
        <NavItem icon-name="logout" />
      </div>
    </div>
  </div>
</template>

<script>
  import { logout } from '@/main.js'
  import VSvg from '@/components/base/Svg.vue'
  import NavItem from '@/components/layout/NavItem.vue'
  import NavAccount from '@/components/layout/NavAccount.vue'
  import { mapState, mapGetters } from 'vuex'
  export default {
    name: 'Navbar',
    components: {
      VSvg,
      NavItem,
      NavAccount
    },
    computed: {
      ...mapState('user', ['years']),
      ...mapGetters('user', ['isSuperAdmin']),
      isDataActive() {
        return (
          this.currentRoute === 'home' || this.currentRoute === '' || this.currentRoute === 'supplierdetail' || this.currentRoute === 'userManage'
        )
      }
    },
    data() {
      return {
        selectedYear: this.$store.getters['user/getCampaignYear'],
        isActive: true,
        currentRoute: ''
      }
    },
    watch: {
      $route(to) {
        this.currentRoute = to.name.toLowerCase()
      }
    },
    created() {
      const NOVEMBER = 11
      const date = new Date()
      if (date.getMonth() >= NOVEMBER) {
        this.selectedYear = date.getFullYear() + 1
        this.$store.commit('user/setCampaignYear', this.selectedYear)
      }
    },
    methods: {
      onChange(event) {
        this.$store.commit('user/setCampaignYear', event.target.value)
      },
      logOut() {
        logout()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navbar {
    background: linear-gradient(90deg, white 60%, $darkblue 99.91%);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2em;

    .logo {
      cursor: pointer;
    }

    nav {
      display: flex;
      a:hover {
        text-decoration: none;
      }
      a:focus {
        text-decoration: none;
      }
      a {
        margin-left: 66px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        color: $lightgrey;
        &.router-link-exact-active {
          color: $pink;
        }
      }
    }
  }
  .navRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .campaignYear {
    font-size: 16px;
    border: none;
    padding-right: 15px;
    color: $darkblue;
    font-weight: bold;
    border-radius: 10px;
  }

  .logout {
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
</style>
