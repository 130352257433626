<template>
  <div class="button opacity" :class="[color ? `button-${color}` : '', disabled ? `button-disabled` : '']" @click="$emit('action')">
    <label class="text-bold">{{ label }}</label>
    <v-svg v-if="icon" class="icon" :name="icon" :size="iconSize" :color="iconColor"></v-svg>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'

export default {
  name: 'VButton',
  components: {
    VSvg
  },
  props: {
    /**
     * button's text
    */
    label: {
      type: String,
      default: ''
    },
    /**
     * button's icon
    */
    icon: {
      type: String,
      default: ''
    },
    /**
     * button's color
    */
    color: {
      type: String,
      default: ''
    },
    /**
     * button's icon color
    */
    iconColor: {
      type: String,
      default: ''
    },
    /**
     * button's icon color
    */
    iconSize: {
      type: String,
      default: '16px'
    },
    /**
     * is the btn disabled
    */
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/styles/_colors";

.button {
  border-radius: 8px;
  padding: 0.6em 1em 0.6em 1em;
  height: fit-content;
  width: fit-content;
  text-align: center;

  &-disabled {
    pointer-events: none;
    color: lightgrey !important;
  }

  &-pink {
    background-color: $pink;
    color: white;
    fill: white;
  }

  &-darkblue {
    background-color: $darkblue;
    color: white;
    fill: white;
  }

  &-transparent {
    &-pink {
      color: $pink;
      fill: $pink;
    }

    &-blue {
      color: $blue;
      fill: $blue;
    }

    &-darkblue {
      color: $darkblue;
      fill: $darkblue;
    }
  }

  .icon {
    margin-left: 0.5em;
  }
}
</style>
