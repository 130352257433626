<template>
  <div :class="[name, 'v-svg']" :style="{ height: size }" v-on="$listeners" v-html="content" />
</template>

<script>
  export default {
    name: 'VSvg',

    props: {
      /**
       * size svg icon
      */
      size: {
        default: '30px',
        type: String
      },
      /**
       * name svg icon
       */
      name: {
        type: String,
        required: true
      },
    },

    data() {
      return {
        content: null
      }
    },

    watch: {
      name: {
        immediate: true,
        async handler() {
          const svgModule = await import(`!svg-inline-loader?classPrefix=true&idPrefix=true!@/assets/svg/${this.name}.svg`)
          this.content = svgModule.default
        }
      }
    }
  }
</script>

<style lang="scss">
  .v-svg {
    display: inline-flex;
    justify-content: center;
    vertical-align: middle; 
  }

  svg {
    height: 100%;
  }  
  
</style>
