
  <template>
    <div class="search">
      <input
        type="text"
        :style="`width: ${width}px`"
        :placeholder="placeholder"
        v-model="search"
        @keydown.enter="emitSearch(search)"
      />
      <v-svg v-if="search" class="icon" name="cross" size="16px" @click="cancel" />
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'

  export default {
    name: 'search',
    components: { VSvg },
    props: {
      /**
       * Component width
      */
      width: {
        type: String,
        default: '640'
      },
      /**
       * Input placeholder
      */
      placeholder: {
        type: String,
        default: function () {
          return this.$t('search')
        }
      },
    },
    data() {
      return {
        search: ''
      }
    },
    methods: {
      emitSearch(val) {
        this.$emit('input', val)
      },
      cancel() {
        this.search = ''
        this.$emit('input', this.search)
      }
    }
  }
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

  .search {
    border-radius: 0.8rem 0.8rem 0 0;
    width: fit-content;
    position: relative;
    background-color: $lightblue;
  }

  .search input {
    background-color: $lightblue;
    border: 0;
    color: $blue;
    padding-left: 4rem;
    height: 4.4rem;
    outline: none;

    &::placeholder {
      color: $blue;
    }
  }

  .search:before {
    content: "";
    position: absolute;
    left: 1rem;
    top: 0;
    bottom: 0;
    width: 2rem;
    background: url('../assets/svg/search.svg') no-repeat right center;
  }

.icon {
  color: $darkblue;
  padding-right: 2rem;
  &:hover {
    cursor: pointer;
  }
}

</style>
