import { extendWithAuthorization, http } from '@/http'
import { sendErrorToast, sendInfoToast } from '@/utils/utils'

const state = {
  userMatriculeConnexion: '',
  campaignYear: new Date().getFullYear(),
  years: [],
  users: [],
  foundUsers: [],
  userDisplayName: '',
  userMail: '',
  firebaseRoles: ''
}

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
  setFoundUsers(state, users) {
    state.foundUsers = users
  },
  setUserMatriculeConnexion(state, userMatriculeConnexion) {
    state.userMatriculeConnexion = userMatriculeConnexion
  },
  setCampaignYear(state, year) {
    state.campaignYear = year
  },
  setUserDispalyName(state, userDisplayName) {
    state.userDisplayName = userDisplayName
  },
  setUserMail(state, userMail) {
    state.userMail = userMail
  },
  setUserFirebaseRoles(state, roles) {
    state.firebaseRoles = roles
  }
}

const actions = {
  async fetchUsers({ commit }, { filter, values }) {
    let users
    const extendedHttp = await extendWithAuthorization(http)

    const searchParams = {
      filter,
      values
    }

    try {
      users = await extendedHttp.get(`user/user`, { searchParams }).json()
    } catch (error) {
      sendErrorToast('Erreur lors de la récupération des utilisateurs')
    }
    commit('setUsers', users)
  },

  async searchUsers({ commit }, params) {
    let users
    const extendedHttp = await extendWithAuthorization(http)
    const keys = Object.keys(params)
    const values = Object.values(params)

    try {
      users = await extendedHttp.get(`user/_search?${keys[0]}=${values[0]}`).json()
    } catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json()

        if (errorJson?.error === 'UserNotFound' || errorJson === 'UserNotFound') {
          sendInfoToast('Utilisateur non trouvé.')
        } else {
          sendErrorToast('Erreur lors de la récupération des utilisateurs')
        }
      }
    }

    commit('setFoundUsers', users)
  },

  async fetchCampaignYear({ state }) {
    const extendedHttp = await extendWithAuthorization(http)
    try {
      state.years = await extendedHttp.get('directory/campaignYear').json()
    } catch (error) {
      sendErrorToast('Erreur lors de la récupération des années')
    }
  }
}

const getters = {
  users(state) {
    return state.users
  },
  getCampaignYear(state) {
    return state.campaignYear
  },
  isAdmin(state) {
    return state.firebaseRoles[process.env.VUE_APP_APP_NAME]?.some(role => ['admin', 'super-admin'].includes(role))
  },
  isSuperAdmin(state) {
    return state.firebaseRoles[process.env.VUE_APP_APP_NAME]?.includes('super-admin')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
