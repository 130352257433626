/**
 * Converts a date to 'DDsymbMMsymbYYYY' format.
 *
 * @param {string} date - The date.
 * @param {string} symb - The symb separate.
 * @return {string} The date in "YYYYsymbMMsymbDD" format.
 */
const convertDateToDDMMYYYY = (date, symb) => {
  // Get date components
  var day = ('0' + date.getDate()).slice(-2)
  var month = ('0' + (date.getMonth() + 1)).slice(-2)
  var year = date.getFullYear()

  // Concatenate components according to the format
  var formattedDate = day + symb + month + symb + year

  return formattedDate
}

module.exports = {
  convertDateToDDMMYYYY
}
