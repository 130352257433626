<template>
  <div class="pagination-container">
    <div class="select">
      <span class="mr-1">{{ $t('pagination.display') }}</span>
      <v-select 
        :options="['10', '25', '50', '100']" 
        :width="'100px'"
        :default-state="perPage.toString()"
        @input="$emit('update-per-page', $event)" 
        />
      <span class="ml-1">{{ $t('pagination.lines') }}</span>
    </div>
    <div class="middle text-bold text-darkblue">
      {{ $t('pagination.lines') }} {{firstLinePageNumber}}-{{lastLinePageNumber}} {{ $t('on') }} {{total}}
    </div>
    <div>
      <ul class="pagination">
        <li class="pagination-item">
          <v-button
            class="rotate"
            @action="onClickFirstPage"
            :disabled="isInFirstPage"
            :icon="'next'"
            :icon-size="'24px'"
            :color="'transparent-blue'"
          />
        </li>

        <li class="pagination-item">
          <v-button
            class="rotate"
            @action="onClickPreviousPage"
            :disabled="isInFirstPage"
            :icon="'fast-forward'"
            :icon-size="'24px'"
            :color="'transparent-blue'"
          />
        </li>

        <li
          v-for="(page, index) in pages"
          :key="index"
          class="pagination-item"
        >
          <v-button
            @action="onClickPage(page.name)"
            :label="page.name.toString()"
            :disabled="page.isDisabled"
            :color="isPageActive(page.name) ? `pink` : 'transparent-darkblue'"
          />
        </li>

        <li class="pagination-item">
          <v-button
            @action="onClickNextPage"
            :disabled="isInLastPage"
            :icon="'next'"
            :icon-size="'24px'"
            :color="'transparent-blue'"
          />
        </li>

        <li class="pagination-item">
          <v-button
            @action="onClickLastPage"
            :disabled="isInLastPage"
            :icon="'fast-forward'"
            :icon-size="'24px'"
            :color="'transparent-blue'"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Button'
import VSelect from '@/components/Select'

export default {
  name: 'VPagination',
  components: {
    VButton,
    VSelect
  },
  props: {
    /**
     * maximum number of visible buttons
    */
    maxVisibleButtons: {
      type: Number,
      default: 3
    },
    /**
     * Number of pages
    */
    totalPages: {
      type: Number,
      required: true
    },
    /**
     * Number of elements
    */
    total: {
      type: Number,
      required: true
    },
    /**
     * Number of elem per page
    */
    perPage: {
      type: Number,
      required: true
    },
    /**
     * Selected page
    */
    currentPage: {
      type: Number,
      required: true
    },
  },
  computed: {
    firstLinePageNumber() {
      return (this.currentPage * this.perPage + 1) - this.perPage
    },
    lastLinePageNumber() {
      return !this.isInLastPage ? (this.currentPage * this.perPage) : this.total
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) { 
        return this.totalPages - this.maxVisibleButtons + 2;
      }

      return this.currentPage - 1;

    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage 
        })
      }

      return range
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);    
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_colors"; 

.pagination-container
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #4AAE9B;
  color: #ffffff;
}

.rotate {
  transform: rotate(180deg);
}
</style>
