import CellRenderer from '@/components/grid/cellRenderer.vue'
// import i18n from '../i18n'

export const WRITE = 1

export const CONTRACT_DATA_RM = 'CONTRACT_DATA_RM'
export const CONTRACT_LIST_RM = 'CONTRACT_LIST_RM'
export const REFRENCES_LIST_RM = 'REFRENCES_LIST_RM'
export const CONTRACT_DATA_APPRO = 'CONTRACT_DATA_APPRO'
export const CONTRACT_LIST_APPRO = 'CONTRACT_LIST_APPRO'

export const DONE = 'DONE'
export const ERROR = 'ERROR'
export const PENDING = 'PENDING'

export const FEMALE_CIVILITIES = ['MLE', 'MELLE', 'MLLE', 'MME']
export const MADAME = 'MME'
