<template>
  <div>
    <div v-if="dataTab.length !== 0" class="data-grid">
      <div class="table">
        <div class="thead">
          <div class="tr">
            <span v-for="(col, key) in limitColumns ? columns.slice(0, limitColumns) : columns" :key="key" class="td" @click="sortBy(key)">
              {{ col }}
            </span>
          </div>
        </div>
        <div class="tbody">
          <div
            v-for="(row, key) in tab"
            :key="key"
            :class="['tr', key & 1 ? 'even' : 'odd', isClickable ? 'clickable' : '']"
            @click="$emit('action-row', key)"
          >
            <span
              v-for="column in limitColumns ? Object.keys(row).slice(0, limitColumns) : Object.keys(row)"
              :key="column"
              class="td"
              v-html="row[column]"
            />
            <span v-if="isClickable" class="td"><v-svg name="chevron-right" :size="'15px'" /></span>
            <span v-if="isEditable" class="td edit">
              <v-svg class="pencil opacity" name="pencil" :size="'20px'" @click="$emit('edit-element', row, key)" />
              <v-svg class="trash opacity" name="trash" :size="'20px'" @click="$emit('delete-element', key)" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty-grid flex">
      {{ noDatasLabel }}
    </div>
  </div>
</template>

<script>
  import VSvg from '@/components/base/Svg.vue'
  export default {
    name: 'DataGrid',
    components: {
      VSvg
    },
    props: {
      /**
       * data tab
       */
      dataTab: {
        type: Array,
        default: () => []
      },
      /**
       * column tab
       */
      columns: {
        type: Array,
        default: () => {}
      },
      /**
       * message when no datas
       */
      noDatasLabel: {
        type: String,
        default: function () {
          return this.$t('noDatas')
        }
      },
      /**
       * is the line clickable
       */
      isClickable: {
        type: Boolean,
        default: false
      },
      /**
       * add edit and delete
       */
      isEditable: {
        type: Boolean,
        default: false
      },
      /**
       * nb column to display
       */
      limitColumns: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        tab: this.dataTab,
        prevKey: '',
        orderDesc: -1,
        columnFromKey: [
          { name: 'paymentSupplierDepartmentLabel', value: 0 },
          { name: 'paymentSupplierCnuf', value: 1 },
          { name: 'paymentSupplierCnufCif', value: 2 },
          { name: 'paymentSupplierLabel', value: 3 },
          { name: 'paymentSupplierNegoGroupLabel', value: 4 }
        ]
      }
    },
    watch: {
      dataTab(val) {
        this.tab = val
      }
    },
    mounted() {
      this.tab = this.dataTab
    },
    methods: {
      sortBy(key) {
        key = this.columnFromKey.find(c => c.value === key)?.name
        if (this.prevKey === key) {
          let vue = this
          this.tab.sort(function compare(a, b) {
            if (a[key] < b[key]) return -1 * vue.orderDesc
            if (a[key] > b[key]) return 1 * vue.orderDesc
            return 0
          })
          this.orderDesc = this.orderDesc * -1
        } else {
          this.tab.sort(function compare(a, b) {
            if (a[key] < b[key]) return -1
            if (a[key] > b[key]) return 1
            return 0
          })
          this.prevKey = key
          this.orderDesc = this.orderDesc * -1
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .odd {
    background-color: white;
  }
  .even {
    background-color: $background;
  }
  .data-grid {
    margin: 10px auto;
    padding: 2px;
    overflow: hidden;
  }
  .table {
    display: table;
    width: 100%;
    font-size: 12px;
    color: black;
  }
  .thead {
    display: table-header-group;
  }
  .tbody {
    display: table-row-group;
  }
  .tr {
    display: table-row;
    border-radius: 8px;
    height: 35px;
  }
  .td {
    display: table-cell;
    position: relative;
    padding: 4px 16px;
    vertical-align: middle;
  }
  .thead .td {
    font-weight: bold;
    cursor: pointer;
  }

  .empty-grid {
    padding: 5rem;
  }

  .clickable:hover {
    opacity: 0.5;
    cursor: pointer;
    background-color: #c5cae9;
  }
  .pencil {
    color: $blue;
    padding-right: 3.5rem;
  }
  .trash {
    color: $pink;
  }
  .edit {
    width: 7rem;
    height: 4rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
</style>
