<template>
  <div class="more-apps">
    <v-button style="color: white" :icon="'account'" @action="showMoreInfos = !showMoreInfos" />
    <transition name="pop">
      <div v-if="showMoreInfos" class="more-apps-popin">
        <div class="app-category">
          <div class="title">
            {{ userMatriculeConnexion }}
          </div>
        </div>
        <div class="app-category">
          <div class="title">
            {{ userDisplayName }}
          </div>
        </div>
        <div class="app-category">
          <div class="title">
            {{ userMail }}
          </div>
        </div>
        <div class="app-category">
          <div class="title"> v{{ appVersion }} </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import VSvg from '@/components/base/Svg'
  import VButton from '@/components/Button.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'NavAccount',
    components: {
      VSvg,
      VButton
    },
    computed: {
      ...mapState('user', ['years', 'userMatriculeConnexion', 'userDisplayName', 'userMail']),
      isDataActive() {
        return this.currentRoute === 'home' || this.currentRoute === '' || this.currentRoute === 'supplierdetail'
      }
    },
    data() {
      return {
        showMoreInfos: false,
        appVersion: process.env.VUE_APP_VERSION
      }
    },
    watch: {
      showMoreInfos() {
        if (this.showMoreInfos) window.addEventListener('click', this.clickOutside)
        else window.removeEventListener('click', this.clickOutside)
      }
    },
    methods: {
      clickOutside(e) {
        this.showMoreInfos = !!this.$el.contains(e.target)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @keyframes popup-scale-y {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }

  .more-apps {
    color: inherit;
    position: relative;
    button {
      color: inherit;
      background: none;
      border: none;
      outline: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: large;
    }
    .more-apps-popin {
      z-index: 30;
      color: black;
      position: absolute;
      top: 100%;
      right: 0;
      background-color: white;
      border-radius: 4px;
      padding-bottom: 14px;
      .app-category {
        padding: 16px 16px 0 16px;
        .title {
          background-color: #cd5483;
          border-radius: 3px 3px 0 0;
          padding: 5px 5px 5px 10px;
          color: white;
        }
        .app-list {
          display: flex;
          .application {
            margin: 6px 12px;
            min-width: 60px;
            transition: all 0.1s ease-out;
            .img-container {
              padding: 5px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 100%;
                border-radius: 4px;
                transition: all 0.4s ease-out;
              }
            }
            .label {
              padding-top: 5px;
              line-height: 15px;
              text-align: center;
              width: 100%;
            }
            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
  .pop-enter-active {
    animation: popup-scale-y 310ms;
    transform-origin: top right;
  }
  .pop-leave-active {
    animation: popup-scale-y 310ms reverse;
    transform-origin: top right;
  }
  .pop-enter,
  .pop-leave-to {
    transform: scale(0);
  }
  .pop-enter-to,
  .pop-leave {
    transform: scale(1);
  }
</style>
