<template>
  <div class="custom-select" @blur="open = false" :style="{ width }">
    <div class="selected" :class="{ open }" @click="open = !open">
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        class="item"
        v-for="(option, i) of options"
        :key="i"
        @click="onClick(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSelect',
  props: {
    /**
     * List of options
    */
    options: {
      type: Array,
      required: true
    },
    /**
     * Start position
    */
    defaultState: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Input width
    */
    width: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      selected: this.defaultState ? this.defaultState : this.options[0],
      open: false
    };
  },
  methods: {
    onClick(option) {
      this.selected = option
      this.open = false
      this.$emit('input', option)
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors"; 

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
}

.selected {
  background-color: #E0E3F8;
  border-radius: 6px;
  color: $darkblue;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
}

.selected.open{
  border-bottom: 1px solid $darkblue;
  border-radius: 6px 6px 0px 0px;
}

.selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: $blue transparent transparent transparent;
}

.items {
  color: $darkblue;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid $darkblue;
  border-left: 1px solid $darkblue;
  border-bottom: 1px solid $darkblue;
  position: absolute;
  background-color: $lightblue;
  left: 0;
  right: 0;
}

.item{
  color: $darkblue;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
}

.item:hover{
  background-color: $darkblue;
  color: #E0E3F8;
}

.selectHide {
  display: none;
}
</style>